<template>
  <div class="w100p" >
    <div class="flex-a-b-c h70 pr20 mb20">
      <div></div>
      <div class="d-flex">
        <el-button size="small"
                     type="primary"
                     @click="initialize_tap">
            恢复默认
          </el-button>
          <el-button size="small"
                     type="primary"
                     @click="save_template">
            保存
          </el-button>
      </div>
    </div>
    <div class="w100p d-flex">

    <!-- left -->
    <div class="w50p flex-c-c pt30 pb30">
        <div class="phoneClass borderboxd-flex flex-column">
          <div class="d-flex oyauto imgScroll" style="height:650px;width:100%;">
            <div class="felx1 text-align">
              <p class="fs10 " style="line-height: 40px;">商城</p>
              <!-- 商城模板 -->
              <div class="d-flex flex-column">
                <div class=""
                  v-for="(moduleList,index) in ecommerceList"
                  :key="index">

                    <!-- 搜索 -->
                    <div class="borderbox pl5 pr5 mb20 posre" v-if="moduleList.type == 'search'" @click="selected_tap(moduleList.type)">
                      <img  :src="moduleList.img" class="w100p" >
                      <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>

                      </div>
                    </div>

                    <!-- tab -->
                    <div class="w100p borderbox pl10 pr10 d-flex justify-content-between flex-wrap-y posre "  v-if="moduleList.type == 'tab'" @click="selected_tap(moduleList.type)">
                        <div class="d-flex flex-column align-items-center bgfff mb10 br10 "  v-for="(item,k) in moduleList.list" :class="k == 3 ? '' : k == 7 ? '' : 'mr24' "  :key="k">
                            <img :src="item.img" class="h45 w45 mb10">
                            <div class="bgfff fs7 pb5 brbottom10 cA2">{{item.title}}</div>
                        </div>
                        <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>

                      </div>
                    </div>
                    <!-- banner -->
                    <div class="pl10 pr10 mb17 posre" v-if="moduleList.type == 'banner1'" @click="selected_tap(moduleList.type)">
                      <img :src="moduleList.list[0].img" class="w100p h96 br10">
                      <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                      </div>
                    </div>

                    <!-- 今日推荐 -->
                    <div class=" pl10 pr10 posre" v-if="moduleList.type == 'diy1'" @click="selected_tap(moduleList.type)">
                      <div class="flex-c-c posre fs9 fwbold lineheight1 mb15">
                        今日推荐
                        <img src="../../../static/departmentStore/title_bg1.png" class="w100 h10 posab choicenessBgImg">
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="d-flex todayRecommend" v-for="(item,k) in moduleList.list" :key="k">
                          <img :src="item.img" class="w100p h100p">
                          <div class="todayRecommendTitle">
                            {{item.title}}
                          </div>
                        </div>
                      </div>

                      <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                      </div>
                    </div>

                    <div class="pl10 pr10 pb10 mt18 mb20 w340 ohidden posre" v-if="moduleList.type == 'seckill'" @click="selected_tap(moduleList.type)">
                      <div class="flex-c-c posre fs9 fwbold lineheight1 mb15">
                      {{moduleList.title}}
                        <img src="../../../static/departmentStore/title_bg2.png" class="w100 h10 posab choicenessBgImg">
                      </div>

                      <div class="d-flex choicenessBox" :class="k == 2 ? '' : 'mb10' "  v-for="(item,k) in moduleList.list" :key="k">
                        <div class="d-flex">
                            <img :src="item.goodPhoto" style="width:109px" class="br10 ohidden h110">
                            <div class="d-flex flex-column justify-content-between">
                              <div class="d-flex justify-content-between align-items-center w180">
                                <div class="">
                                  <p class="fs8 lineheight1 pt15 pb10">{{item.goodsName}}</p>
                                  <div class="d-flex pb10">
                                    <p class="fs9 corange lineheight1 mr5">￥{{(item.minKillPrice/100) || 0}}</p>
                                    <div class="d-flex align-items-end pb2 text-decoration fs6 cA3 lineheight1">￥{{(item.maxPrice/100) || 0}}</div>
                                  </div>
                                </div>
                                <div class="Immediatelykill">
                                  <p>立即</p>
                                  <p>秒杀</p>
                                </div>
                              </div>
                              <p></p>
                              <countDown :type="1"
                                         :diffTime="86400"></countDown>
                            </div>

                        </div>
                      </div>
                      <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                      </div>
                    </div>



                    <div class="pl10 pr10  mb17 mr9 ml10 pb10 group_bgcolor br10 posre" v-if="moduleList.type == 'group'" @click="selected_tap(moduleList.type)">
                      <div class="h45 w100p group_title lineheight45 fs9 cfff textc">
                        {{moduleList.title}}
                      </div>
                      <div class="pt10 pb10 pl10 pr10 bgfff br10"
                          :class="k == 0 ? 'mb10' : '' "
                           v-for="(item,k) in moduleList.list"
                           :key="k">
                        <div class="w100p h163 ohidden mb10 borderbox">
                          <img :src="item.goodPhoto" class="w100p ">
                        </div>

                        <div class="d-flex flex-column justify-content-between pb5  posre flex1">
                          <div class="flex-a-c mb10">
                            <div class="fs6 br10 cfff pb3 pb3 pl10 pr10 mr5 bgorange flex-c-c">{{item.peopleNum}}人团</div>
                            <p class="fs8 fwbold">{{item.goodsName}}</p>
                          </div>
                          <div class="d-flex">
                            <p class="fs9 corange lineheight1 mr5">￥{{(item.minAssemblePrice/100) || 0}}</p>
                            <div class="d-flex align-items-end pb2 text-decoration fs6 cA3 lineheight1">￥{{(item.maxPrice/100) || 0}}</div>
                          </div>
                          <div class="posab purchase">
                            <i class="el-icon-shopping-cart-1 fs8 mr5"></i>
                            <p>一键开团</p>
                          </div>
                        </div>
                      </div>
                      <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>

                      </div>
                    </div>

                    <div class="mr10 ml10 pb20 posre" v-if="moduleList.type == 'boutique'" @click="selected_tap(moduleList.type)">
                      <div class="flex-c-c posre fs9 fwbold lineheight1 mb15">
                        {{moduleList.title}}
                        <img src="../../../static/departmentStore/title_bg3.png" class="w100 h10 posab choicenessBgImg">
                      </div>
                      <div class="d-flex flex justify-content-between flex-wrap-y">
                        <div class="choicenessBoxA" :class="{'mr18':k%2 != 1}" v-for="(item,k) in moduleList.list" :key="k" >
                          <img :src="item.goodPhoto" class="w130 h130 mb10">
                          <div class="textc  pl9 pr9">
                            <p class="corange fs8">￥{{(item.price/100)}}</p>
                            <p class="cA1 fs7">{{item.goodsName}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' " >
                        <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                        <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                        <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                      </div>
                    </div>
                  </div>

              </div>
            </div>
          </div>

          <div class="d-flex flex1 bgfff justify-content-between">
            <div style="width:52px;height:100%"
                 class="flex-c-c flex-column"
                 v-for="(item,index) in tabList"
                 :key="index">
                <img :src="index == 2 ? item.selectedIconPath : item.iconPath " class="w40 h40">
                <p class="fs6">{{item.text}}</p>
            </div>
          </div>
        </div>
    </div>
    <!-- right -->
    <div class="w50p d-flex oyauto oscrollbar">
      <div class="flex1 h550 pt20 pl20 pb20 w100p">
        <div class="mt20" v-for="(moduleList,index) in ecommerceList" :key="index">

          <!-- tab -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-if="currentType == 'tab' && moduleList.type == currentType">
            <tab :listData="moduleList"
                  @confirmSeckill="confirmSeckill"
                  @reset_tab_tap="reset_tab_tap">
            </tab>

          </div>
          <!-- diy1 -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-else-if="currentType == 'diy1' && moduleList.type == currentType">
            <p class="textc fs9 mb20 fwbold">{{moduleList.title}}</p>
            <div class="mb20 posre " v-for="(item,k) in moduleList.list" :key="k">
              <p class="fs8 fwbold mb10">图片{{k+1}}</p>
              <p class="fs8 mb10">文案设置</p>
              <el-input :placeholder="item.title" v-model="item.title" maxlength="4" show-word-limit class="mb10 w200 "></el-input>
              <div class="bgf7fa pa10 d-flex align-items-center">
                <div class="d-flex flex-column align-items-center ">
                <el-upload
                        accept="image/jpeg,image/png"
                        class="addTabIcon"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :on-success="bannerPicSuccess.bind(null, {'type':currentType,'index':k,'data':item})"
                        :before-upload="beforeUploadImageDynamicPic">
                    <img v-if="item.img" :src="item.img" class="avatar dynamic ">
                    <i v-else class="el-icon-plus"></i>
                </el-upload>
                <p class="fs6">建议上传335px * 200px的图片效果最佳</p>
                </div>
                <jumpSet title="123123123"
                        :index="currentIndex"
                        :currentGoods="item"
                        :currentSelect="k"
                        :goodsData="item"
                        @currentSelect_tap="currentSelect_tap"
                        @select_tap="select_tap"></jumpSet>
              </div>

            </div>

          </div>
          <!-- 秒杀 -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-else-if="currentType == 'seckill' && moduleList.type == currentType">
            <specialGoods :listData="moduleList"
                          type="seckill"
                          @confirmSeckill="confirmSeckill">

            </specialGoods>
          </div>
          <!-- banner1 -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-else-if="currentType == 'banner1' && moduleList.type == currentType">
            <p class="textc fs9 mb20 fwbold">图片模块</p>
            <div class="bgf7fa pa10 d-flex align-items-center mb20 posre" v-for="(item,k) in moduleList.list" :key="k">
              <div class="d-flex flex-column align-items-center ">
                <el-upload
                      accept="image/jpeg,image/png"
                      class="addTabIcon"
                      :action="uploadUrl"
                      :show-file-list="false"
                      :on-success="bannerPicSuccess.bind(null, {'type':currentType,'index':k,'data':item})"
                      :before-upload="beforeUploadImageDynamicPic">
                  <img v-if="item.img" :src="item.img" class="avatar dynamic ">
                  <i v-else class="el-icon-plus"></i>
              </el-upload>
              <p class="fs6">建议上传690px * 210px的图片效果最佳</p>
              </div>
              <jumpSet title="123123123"
                      :index="currentIndex"
                      :currentGoods="item"
                      :currentSelect="k"
                      :goodsData="item"
                      @currentSelect_tap="currentSelect_tap"
                      @select_tap="select_tap"></jumpSet>
            </div>
          </div>

          <!-- 拼团 -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-else-if="currentType == 'group' && moduleList.type == currentType">
            <specialGoods :listData="moduleList"
                          type="group"
                          @confirmSeckill="confirmSeckill">

            </specialGoods>
          </div>

          <!-- 精选商品 -->
          <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500" v-else-if="currentType == 'boutique' && moduleList.type == currentType">
            <specialGoods :listData="moduleList"
                          type="isOrdinary"
                          @confirmSeckill="confirmSeckill">
            </specialGoods>
          </div>

        </div>
      </div>



    </div>

    </div>
  </div>
</template>

<script>
import {
  saveShopModelConfig,
  queryShopModelConfig,
  updateShopModelConfig
} from "@/api/wechat";
import { getDataTimeSec } from "@/utils";
import jumpSet from "./components/jumpSet";
import specialGoods from './departmentStore/specialGoods'
import tab from './ecommerce/tab'
import countDown from './departmentStore/countDown'


export default {
  data() {
    return {
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      isUpdate:false,
      tabList:[
        {
          text: "名片",
          pagePath: "pages/index/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/card.png",
          selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/card_select.png"
        },
        {
          text: "媒体",
          pagePath: "pages/media/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/video.png",
          selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/video_select.png"
        },
        {
          text: "商城",
          pagePath: "pages/Products/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/prod_gray.png",
          selectedIconPath:this.$store.state.ossUrl + "yimai_photos/tabbarIcon/prod_select.png"
        },
        {
          text: "动态",
          pagePath: "pages/Dynamic/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/browser.png",
          selectedIconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/browser_select.png"
        },
        {
          text: "官网",
          pagePath: "pages/WebSite/main",
          iconPath:this.$store.state.ossUrl +  "yimai_photos/tabbarIcon/computer.png",
          selectedIconPath:this.$store.state.ossUrl + "yimai_photos/tabbarIcon/computer_select.png"
        },
      ],
      ecommerceList:[{
        img:require('../../../../src/static/icon_search.png'),
        type:'search'
      },{
        list:[{
          img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/tab1.png',
          title:'个人护理'
        },{
          img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/tab2.png',
          title:'家务清洁'
        },{
          img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/tab3.png',
          title:'母婴用品'
        },{
          img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/tab4.png',
          title:'防护用品'
        },{
          img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/tab5.png',
          title:'家纺用品'
        },{
          img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/tab6.png',
          title:'休闲零食'
        },{
          img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/tab7.png',
          title:'限时秒杀'
        },{
          img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/tab8.png',
          title:'拼团抢购'
        }],

        type:'tab'
      },{
        type:'banner1',
        list:[{
          img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/banner1.png',
          goods:'',
          page:'',
        }],
      },{
        list:[
          {
            img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/goodsA1.png',
            title:'防疫口罩',
            goods:'',
            page:'',
          },
          {
            img:'https://jkt-one-stand.oss-cn-huhehaote.aliyuncs.com/yimai_photos/departmentStore/goodsA2.png',
            title:'迷你雨伞',
            goods:'',
            page:'',
          },
        ],
        title:'今日推荐模块',
        type:'diy1',
      },{
        type:'seckill',
        title:'限时秒杀',
        list:[
          {
            goodsName:'精品毛巾',
            goodsId:'',
            goodPhoto:require('../../../../src/static/departmentStore/goodsB1.png'),
            minKillPrice:'88800',
            maxPrice:'888800',
            time:'2021-07-07T08:22:39.000+0000'
          },
          {
            goodsName:'扫地机器人',
            goodsId:'',
            goodPhoto:require('../../../../src/static/departmentStore/goodsB2.png'),
            minKillPrice:'88800',
            maxPrice:'888800',
            time:'2021-07-07T08:22:39.000+0000'
          },
          {
            goodsName:'好吃零食',
            goodsId:'',
            goodPhoto:require('../../../../src/static/departmentStore/goodsB3.png'),
            minKillPrice:'88800',
            maxPrice:'888800',
            time:'2021-07-07T08:22:39.000+0000'
          }
        ]
      },{
        type:'group',
        title:'拼团抢购',
        list:[
          {
            goodsName:'夏季新款T恤',
            goodsId:'',
            goodsNum:2,
            goodPhoto:require('../../../../src/static/departmentStore/goodsC1.png'),
            minAssemblePrice:'88800',
            maxPrice:'888800'
          }
        ]
      },{
        type:'boutique',
        title:'推荐好货',
        list:[
          {
            goodsName:'超级美味祖传秘方好吃饼干',
            goodPhoto:require('../../../../src/static/departmentStore/goodsD1.png'),
            price:'9990',
            maxPrice:'888800'
          },
          {
            goodsName:'扫把',
            goodPhoto:require('../../../../src/static/departmentStore/goodsD2.png'),
            price:'88800',
            maxPrice:'888800'
          },
          {
            goodsName:'洗衣机',
            goodPhoto:require('../../../../src/static/departmentStore/goodsD3.png'),
            price:'9990',
            maxPrice:'888800'
          },
          {
            goodsName:'ins风白色半透明仙女窗帘',
            goodPhoto:require('../../../../src/static/departmentStore/goodsD4.png'),
            price:'88800',
            maxPrice:'888800'
          }
        ]
      }],
      currentType:'',
      requestWay:'save',
      currentIndex:0,
      currentSelect:'',
      initializeData:""
    };
  },
  components:{
    jumpSet,
    specialGoods,
    tab,
    countDown
  },
  created(){
    this.initializeData = JSON.parse(JSON.stringify(this.ecommerceList))
    this.queryShopConfig()
  },
  mounted(){

  },
  methods:{
    queryShopConfig(){
        let data = {
          modelEnum:'daily_necessities',
        }
        queryShopModelConfig(data)
        .then(res=>{
          if(res.data.modelId){
            this.requestWay = 'up'
            this.ecommerceList = JSON.parse(res.data.shopDetails[0].content)
          }
        })
      },
    selected_tap(type){
      let index = ''
      this.ecommerceList.forEach((item,k) =>{
        if(item.type == type){
          this.currentIndex = k
        }
      })
      this.currentType = type
    },
    bannerPicSuccess(obj,res,file){
      //动态图上传成功
      let than = this
      this.ecommerceList.forEach((item,k) =>{
        if(item.type == obj.type){

          than.$set(than.ecommerceList[k].list[obj.index], 'img', this.ossUrl + '/' +res.data)
        }
      })
    },
    // 上移
    templateUp(){

      let arr = this.ecommerceList,
          index = this.currentIndex
      if(index !== 0){
        this.swapArray(arr,this.currentIndex,--this.currentIndex)
      }else{
        this.$message.error('已经处于置顶，无法上移')
      }
    },
    templateDown(){

      let arr = this.ecommerceList,
          length = this.ecommerceList.length,
          index = this.currentIndex
      if(index+1 != length){
        this.swapArray(arr,this.currentIndex,++this.currentIndex)
      }else{
        this.$message.error('已经触底，无法下移')
      }
    },
    templateDelete(){
      let index = this.currentIndex
      this.ecommerceList.splice(index,1)
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    beforeUploadImageDynamicPic(file){
      var _this = this;
      var isLt10M = file.size / 1024 / 1024  < 10;
      if (['image/jpeg', 'image/png'].indexOf(file.type) == -1) {
          _this.$message.error('请上传正确的图片格式');
          return false;
      }
      if (!isLt10M) {
          _this.$message.error('上传图片大小不能超过10MB哦!');
          return false;
      }
    },
    currentSelect_tap(index){
      this.currentSelect = index
    },
    select_tap(index,val,type,goods,currentSelect){
      if(goods){
        if(currentSelect || currentSelect === 0){
          this.ecommerceList[this.currentIndex].list[currentSelect].goods = goods
          delete this.ecommerceList[this.currentIndex].list[currentSelect].page
        }else{
          this.ecommerceList[this.currentIndex].list[this.currentSelect].goods = goods
          delete this.ecommerceList[this.currentIndex].list[this.currentSelect].page
        }page
      }else{
        if(type){
          if(currentSelect || currentSelect === 0){
            this.ecommerceList[this.currentIndex].list[currentSelect].page = type
            delete this.ecommerceList[this.currentIndex].list[currentSelect].goods
          }else{
            this.ecommerceList[this.currentIndex].list[this.currentSelect].page = type
            delete this.ecommerceList[this.currentIndex].list[this.currentSelect].goods
          }
        }

      }
    },

    //重置数据
    initialize_tap(){
      this.ecommerceList = JSON.parse(JSON.stringify(this.initializeData))
    },
    //保存模板
    save_template(){
      let than = this
      let arr = JSON.parse(JSON.stringify(this.ecommerceList))
      let p = new Promise((resolve,reject) => {
        arr.forEach((item,k)=>{
          if(item.list){
            item.list.forEach((v,k)=>{
              if(item.type == 'seckill'){
                if(!v.goodsId){
                  reject("秒杀模块未设置商品")
                }
              }
              if(item.type == 'group'){
                if(!v.goodsId){
                  reject("拼团模块未设置商品")
                }
              }
              if(item.type == 'boutique'){
                if(!v.goodsId){
                  reject("精选模块未设置商品")
                }
              }

            })
          }
        })
        resolve('succ')
      })
      p.then(res => {
        let data = {
          modelEnum:'daily_necessities',
          shopDetails:[{
            content:JSON.stringify(arr)
          }]
        }
        if(than.requestWay == 'save'){
          saveShopModelConfig(data)
          .then(res=>{
            if(res.code == 200){
              than.$message.success("保存成功")
              setTimeout(()=>{
                than.queryShopConfig()
                than.$router.push('mallSetting')
              },500)
            }
          })
          .catch(err => {
            if(err.code == 201){
              this.$message.error(err.message)
            }
          })
        }else{
          updateShopModelConfig(data)
          .then(res=>{
            if(res.code == 200){
              than.$message.success("保存成功")

              setTimeout(()=>{
                than.queryShopConfig()
                than.$router.push('mallSetting')
              },500)
            }
          })
          .catch(err => {
            if(err.code == 201){
              this.$message.error(err.message)
            }
          })
        }
      })
      .catch(err => {
        this.$message.error(err)
      })
    },
    //重置tab
    reset_tab_tap(data){

      let than = this
      than.ecommerceList.forEach((item,k)=>{
        if(item.type == 'tab'){
          than.$set(than.ecommerceList,k,data)
        }
      })
    },
    //确认秒杀商品
    confirmSeckill(index,data,type){
      let than = this
      than.ecommerceList.forEach((item,k)=>{
        if(item.type == type){
          than.ecommerceList[k].list[index] = data
          than.$set(than.ecommerceList[k].list,index,data)
        }
      })

    }
  }
};
</script>

<style lang="scss" scoped>
.phoneClass{
  padding:50px 30px 0px 30px;
  background-image:url('../../../assets/images/iphonex.png') ;
  width:400px;
  height:800px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.imgScroll::-webkit-scrollbar { width: 0 !important }
.group_title{
  background-image:url('../../../static/ecommerce/bg1.png');
  background-repeat:no-repeat;
  background-size: 100%;
}
.group_bgcolor{
  background: linear-gradient(0deg, rgba(253, 78, 86, 0.3), #FD4E56);
}
.purchase{
  width: 103px;
  height: 32px;
  background: #FFA133;
  color: #fff;
  border-radius: 16px 0px 0px 16px;
  right: -10px;
  bottom: 15px;
  display: flex;
  font-weight: bold;
  align-items: center;
  padding-left: 11px;
  font-size: 16px;
}
.choicenessBgImg{
  bottom: 0px;
}
.fade_in{
  opacity: 1;
  z-index: 99;
  transition:all 0.2s;
}
.fade_out{
  opacity: 0;
  display: none !important;
  transition:all 0.2s;
}
.control{
  right: 0px;
  top: 0px;
  width: 25px;
  height: 150px;
  background: #51CDCB;
  z-index: 99;
  display: flex;
  justify-content:space-around;
  flex-direction: column;
  font-size: 18px;
}
 /deep/.addTabIcon .el-upload--text{
  width: 210px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 10px;
}
/deep/ .addTabIcon .el-upload-list__item {
  width: 210px;
  height: 100px;
  line-height: 100px;
}
.avatar{
  width: 100%;
  height: 100%;
}
.todayRecommend{
  width: 152px;
  height: 91px;
  border-radius: 10px;
  position: relative;
}
.todayRecommendTitle{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 27px;
  background: linear-gradient(0deg, #000000, rgba(0, 0, 0, 0));
  color: #fff;
  font-size: 13px;
  line-height: 27px;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
}
.choicenessBox{
  padding:9px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
  display: flex;
}
.choicenessBoxA{
  padding:9px;
  width: 150px;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
  border-radius: 9px;
}
.Immediatelykill{
  width: 45px;
  height: 45px;
  background: rgba(253, 78, 86,0.08);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FD4E56;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.4;
}

</style>
